<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.my_patients") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <div class="btn excel_btn" v-loading.body="loadingData">
          <button
              @click="statisticFilter(item, index)"
              class="filter-wrap__btn"
              :class="{ active: index == activeFilterIndex }"
              v-for="(item, index) in items"
              :key="index"
          >
            {{ $t(`message.${item}`) }}
          </button>
        </div>
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.doctor"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.secondary_doctor"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>
          <th v-if="columns.service.show">
            <el-input
              size="mini"
              v-model="filterForm.service_name"
              :placeholder="columns.service.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.doctor.show">
            <el-select
              filterable
              clearable
              :placeholder="columns.doctor.title"
              size="mini"
              v-model="filterForm.doctor_id"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.surname + ' ' + item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </th>
          <th v-if="columns.secondary_doctor.show">
            <!-- <el-select
              filterable
              clearable
              :placeholder="columns.doctor.title"
              size="mini"
              v-model="filterForm.doctor_id"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.surname + ' ' + item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            ></el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id + '-' + patient.service_id"
          style="cursor: pointer"
        >
          <template v-if="activeItem === 'ambulatory'">
            <td v-if="columns.id.show">
              {{ ambulatoryId(patient) }}
            </td>
            <td v-if="columns.patient_id.show">
              {{
                patient.patient && patient.patient.surname
                    ? patient.patient.surname
                    : ""
              }}
              {{ patient.patient ? patient.patient.first_name : "" }}
            </td>
            <td v-if="columns.day.show">{{ patient.day }}</td>
            <td v-if="columns.service.show">
              {{ patient.service ? patient.service.name : "" }}
            </td>
            <td v-if="columns.doctor.show">
              {{
                patient.doctor
                    ? patient.doctor.surname + " " + patient.doctor.name
                    : ""
              }}
            </td>
            <td v-if="columns.secondary_doctor.show">
              {{
                patient.secondary_doctor
                    ? patient.secondary_doctor.surname +
                    " " +
                    patient.secondary_doctor.name
                    : ""
              }}
            </td>
            <td v-if="columns.created_at.show">
              {{ patient.created_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <div class="za_btn">
                <el-button type="success">
                  <router-link
                      style="color: #fff"
                      :to="{
                    name: 'patientConclusions',
                    params: { id: patient.order_id },
                  }"
                  >{{ $t("message.show_0") }}</router-link
                  >
                </el-button>
                <el-button
                    v-if="patient.service.unnecessary_enclosure"
                    @click="closeOrder(patient.id)"
                    type="primary"
                    icon="el-icon-edit"
                >
                  {{ $t("message.close") }}
                </el-button>
                <el-button
                    v-else
                    @click="dialogOpen(patient)"
                    type="primary"
                    icon="el-icon-edit"
                >
                  {{ $t("message.conclusions") }}
                </el-button>
                <el-button
                    @click="returnBack(patient)"
                    icon="el-icon-close"
                ></el-button>
              </div>
            </td>
          </template>
          <template v-if="activeItem === 'stationary'">
            <td v-if="columns.id.show">
              {{ patient.patient_history_id + "-" + patient.service_id + "-" + patient.patient_history.branch_id}}
            </td>
            <td v-if="columns.patient_id.show">
              {{
                patient.patient_history.patient && patient.patient_history.patient.surname
                    ? patient.patient_history.patient.surname
                    : ""
              }}
              {{ patient.patient_history.patient ? patient.patient_history.patient.first_name : "" }}
            </td>
            <td v-if="columns.day.show">{{ patient.patient_history.date_gos }}</td>
            <td v-if="columns.service.show">
              {{ patient.service ? patient.service.name : "" }}
            </td>
            <td v-if="columns.doctor.show">
              {{
                patient.doctor
                    ? patient.doctor.surname + " " + patient.doctor.name
                    : ""
              }}
            </td>
            <td v-if="columns.secondary_doctor.show">
              {{
                patient.secondary_doctor
                    ? patient.secondary_doctor.surname +
                    " " +
                    patient.secondary_doctor.name
                    : ""
              }}
            </td>
            <td v-if="columns.created_at.show">
              {{ patient.created_at }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <div class="za_btn">
                <el-button
                    v-if="patient.service.unnecessary_enclosure"
                    @click="closeOrder(patient.id)"
                    type="primary"
                    icon="el-icon-edit"
                >
                  {{ $t("message.close") }}
                </el-button>
                <el-button
                    v-else
                    @click="dialogOpen(patient)"
                    type="primary"
                    icon="el-icon-edit"
                >
                  {{ $t("message.conclusions") }}
                </el-button>
                <el-button
                    @click="returnBack(patient)"
                    icon="el-icon-close"
                ></el-button>
              </div>
            </td>
          </template>
        </tr>
      </transition-group>
    </table>

    <el-dialog
      :title="choose_template_header"
      :append-to-body="true"
      :visible.sync="outerOrderVisible"
      width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            clearable
            :placeholder="$t('message.search_template')"
            v-model="filterText"
          ></el-input>
          <el-tree
            class="mt-2 filter-tree"
            :data="data"
            :props="defaultProps"
            node-key="id"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()">
          {{ $t("message.close") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="choose_template_header"
      :append-to-body="true"
      :visible.sync="signOuterVisible"
      width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24" v-for="(item, index) in signs" :key="index">
          {{ item.name }}
          <el-input clearable v-model="signs_form[item.sign]"></el-input>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusionSign()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogCloseSign()">
          {{ $t("message.close") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="Выберите шаблон"
        :append-to-body="true"
        :visible.sync="outerHospitalVisible"
        width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
              clearable
              :placeholder="$t('message.search_template')"
              v-model="filterText"
          ></el-input>
          <el-tree
              class="mt-2 filter-tree"
              :data="data"
              node-key="id"
              :filter-node-method="filterNode"
              ref="tree"
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>

    <el-drawer
      :visible.sync="drawerCreateCoculation"
      size="100%"
      :wrapperClosable="false"
      :drawer="drawerCreateCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
          ref="createDrawer"
        ></crm-create>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createConclusion";
import axios from "axios";
import list from "@/utils/mixins/list";
import Inventory from "../../components/inventory-select/select-service_name-inventory";
import { i18n } from "@/utils/i18n";
export default {
  mixins: [list],
  name: "PatientServiceList",
  components: {
    CrmCreate,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      loadingPatientButton$id: false,
      drawerCreateCoculation: false,
      outerHospitalVisible: false,
      choose_template_header: '',
      filterForm: {},
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      form: {},
      loadingObj: {},
      loadingData: false,
      selected_patient: [],
      backUrl: process.env.VUE_APP_URL_DOCS,
      outerOrderVisible: false,
      signOuterVisible: false,
      model: null,
      filterText: "",
      data: null,
      signs: [],
      signs_form: {},
      activeItem: null,
      activeFilterIndex: 1,
      items: {
        1: "ambulatory",
        2: "stationary"
      },
      defaultProps: {
        children: 'children',
        label: 'label',
        disabled: 'disabled',
      }
    };
  },
  computed: {
    ...mapGetters({
      list: "doctorCabinet/patient_list",
      columns: "doctorCabinet/columnsNew",
      pagination: "doctorCabinet/pagination",
      filter: "doctorCabinet/filter",
      user_id: "auth/id",
      sort: "doctorCabinet/sort",
      conditions: "conditions/list",
      // partner_doctors: "partnerDoctor/list",
      token: "auth/token",
      users: "users/inventory_general",
    }),
  },
  watch: {
    activeItem: {
      handler: function () {
        this.fetchData();
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
    if (this.users && this.users.length === 0) await this.getUsers();
    this.categoryDocumentationList({ relation: true })
      .then((res) => {
        this.data = res.data.category_forms;
      })
      .catch((err) => {
        console.log(err);
      });
    this.defaulFilterItem = this.items[this.activeFilterIndex];
    this.statisticFilter(this.defaulFilterItem, this.activeFilterIndex);
  },
  methods: {
    ...mapActions({
      updateList: "doctorCabinet/patientList",
      showModel: "orders/relationShow",
      categoryDocumentationList: "categoryDocumentation/index",
      updateSort: "doctorCabinet/updateSort",
      updateFilter: "doctorCabinet/updateFilter",
      updateColumn: "doctorCabinet/updateColumnNew",
      updatePagination: "doctorCabinet/updatePagination",
      loadTemps: "formTemplate/list",
      empty: "doctorCabinet/empty",
      refreshData: "doctorCabinet/refreshData",
      setOrderSigns: "doctorCabinet/setSigns",
      setHospitalSigns: "hospitalizationServices/setSigns",
      returnInformalDoctor: "doctorCabinet/returnInformalDoctor",
      // loadPartnerDoctors: "partnerDoctor/index",
      loadConditions: "conditions/inventory",
      getUsers: "users/inventory_general",
      currentUser: "auth/getAuth",
      close: "orders/orderClose",
    }),
    ambulatoryId(model) {
      let id = model.order_id + "-" + model.service_id;
      if (model.position > 1) id += "(" + model.position + ")";
      return id;
    },
    stationaryId(model) {
      let id = model.patient_history_id + "-" + model.service_id + "-" + model.patient_history.branch_id;
      if (model.position > 1) id += "(" + model.position + ")";
      return id;
    },
    returnBack(patient) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.returnInformalDoctor(patient.id)
            .then((res) => {
              this.$loadingCursor("default");
              this.refresh();
            })
            .catch((err) => {
              this.$loadingCursor("default");
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.operation_canceled"),
          });
        });
    },
    async fetchData() {
      const user = await this.currentUser();
      const query = {
        type: this.activeItem,
        doctor: user.name,
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.fetchData();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    dialogClose() {
      this.$refs.tree.setCheckedNodes([]);
      this.outerOrderVisible = false;
      this.form = {};
      this.model = null;
    },
    dialogCloseSign() {
      this.signOuterVisible = false;
      this.signs = [];
    },
    documentOpen(service_id, patient_id) {
      this.service_id = service_id;
      this.patient_id = patient_id;
      this.outerHospitalVisible = true;
    },
    dialogOpen(model) {
      this.model = model;
      let name;
      if (this.activeItem === 'ambulatory') {
        name =
            this.ambulatoryId(model) +
            " " +
            model.patient.surname +
            " " +
            model.patient.first_name;
      } else if (this.activeItem === 'stationary') {
        name =
            model.patient_history_id +
            "-" +
            model.service_id +
            "-" +
            model.patient_history.branch_id +
            " " +
            model.patient.surname +
            " " +
            model.patient.first_name;
      }
      this.choose_template_header = name + ' \n\r (' + model.service.name + ')';
      axios({
        url: this.backUrl + "/check/" + name,
        method: "GET",
      })
          .then((res) => {
            if (res.data == true) {
              if (this.activeItem === 'ambulatory') {
                this.createOrderModal(model, null);
              }
              else if (this.activeItem === 'stationary') {
                this.createHospitalModal(model.service_id, model.patient_history.patient.id, null);
              }
            } else {
              axios({
                url:
                    this.backUrl +
                    "/checkLaboratory/" +
                    this.activeItem +
                    "/" +
                    (model.order_id ? model.order_id : model.patient_history_id) +
                    "/" +
                    model.service_id,
                method: "GET",
              })
                  .then((res) => {
                    if (res.data == false) this.outerOrderVisible = true;
                    else {
                      this.signs = res.data;
                      this.signOuterVisible = true;
                    }
                  })
                  .catch((err) => {
                    this.outerOrderVisible = true;
                  });
            }
          })
          .catch((err) => {
            this.outerOrderVisible = true;
          });
    },
    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();

      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }
      if (this.activeItem === 'ambulatory') {
        this.createOrderModal(this.model, template_id);
      } else if (this.activeItem === 'stationary') {
        this.createHospitalModal(this.model.service_id, this.model.patient.id, template_id);
      }
    },
    createConclusionSign() {
      let model = this.model;
      let data = {};
      if (this.activeItem === 'ambulatory') {
        data["fileName"] =
          this.ambulatoryId(model) +
          " " +
          model.patient.surname +
          " " +
          model.patient.first_name;

        data["order_id"] = model.order_id;
        data["user"] = this.token;
        data["service_id"] = model.service_id;
        data["position"] = model.position;
        data["patient_id"] = model.patient_id;
        data["user_id"] = this.user_id;
        data["signs"] = this.signs_form;

        this.setOrderSigns(data)
          .then((res) => {
            this.signOuterVisible = false;
            this.signs_form = {};
            this.fetchData();
          })
          .catch((err) => {});
      } else if (this.activeItem === 'stationary') {
        data["fileName"] =
          model.patient_history_id +
          "-" +
          model.service_id +
          " " +
          model.patient.surname +
          " " +
          model.patient.first_name;

        data["patient_history_id"] = model.patient_history_id;
        data["user"] = this.token;
        data["service_id"] = model.service_id;
        data["patient_id"] = model.patient_history.patient.id;
        data["user_id"] = this.user_id;
        data["signs"] = this.signs_form;

        this.setHospitalSigns(data)
          .then((res) => {
            this.signOuterVisible = false;
            this.signs_form = {};
            this.fetchData();
          })
          .catch((err) => {});
      }
    },
    handleNodeClick(node, data, value) {},
    async createOrderModal(model, template_id) {
      var name =
        this.ambulatoryId(model) +
        " " +
        model.patient.surname +
        " " +
        model.patient.first_name;
      var link = document.createElement("a");
      var service_id = model.service_id;
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation?order_id=" +
          model.order_id +
          "&name=" +
          name +
          "&template_id=" +
          template_id +
          "&service_id=" +
          service_id +
          "&position=" +
          model.position +
          "&user=" +
          this.token +
          "&status=create" +
          "&user_type=doctor"
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();

      return;
    },
    async createHospitalModal(service_id, patient_id, template_id) {
      var link = document.createElement("a");
      link.setAttribute(
          "href",
          this.backUrl +
          "/file_orientation_patient_room?template_id=" +
          template_id +
          "&user=" +
          this.token +
          "&status=create" +
          "&service_id=" +
          service_id +
          "&patient_id=" +
          patient_id
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();
      return;
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
    closeOrder(id) {
      this.close(id);
      this.refreshData();
    },
    statisticFilter(item, index) {
      this.activeItem = item;
      this.activeFilterIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;

    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>
<style>
.el-tree-node__expand-icon {
  color: #0a0a0a;
  font-size: 16px;
}
.is_leaf {
  color: transparent;
}
</style>
